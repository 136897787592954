import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Detail from './pages/detail/Detail';
import Search from './pages/search/Search';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/search" Component={Search} />
				<Route path="/detail" Component={Detail} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
